import odoo_api from '../../FunzioniBE/odoo_lobra_wsapi'

export const setCurrentUser = info365 => ({
    type: 'OFFICE_365_LOGIN',
    payload: info365
})

export const getOdooUserInfo = email => ({ 
    type: 'GET_ODOO_USER_BYEMAIL',
    payload: odoo_api.getuser_by_email(email)
})

export const getOdooUserInfo_forced = email => ({ 
    type: 'GET_ODOO_USER_BYEMAIL_FORCED',
    payload: odoo_api.getuser_by_email(email)
})

export const setForcePersonationTrue = () => ({
    type: 'SET_FORCE_RELOAD_TRUE'
})

export const setForcePersonationFalse = () => ({
    type: 'SET_FORCE_RELOAD_FALSE'
})

export const getAllExternalEmp = () => ({
    type: 'GET_ALL_EXT_EMPLOYEE',
    payload: odoo_api.getall_external_emp()
})
