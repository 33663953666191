import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";
const FromString = require('from-string')

const INITIAL_STATE = {
    operation_on_date: 0,
    selected_timesheet_month: moment().add(0, 'months').format('MM'),
    selected_timesheet_year: moment().add(0, 'months').format('YYYY'),
    selected_timesheet_date: function(){ return this.selected_timesheet_year + '-' + this.selected_timesheet_month},
    followed_project_requested: false,
    followed_prj: {},
    high_light_day: 0,
    timesheet_act_requested: false,
    timesheet_act: {},
    timesheet_to_update: [],
    timesheet_to_insert: [],
    timesheet_indraft: [],
    month_closed: false,
    request_again_timesheet: false,
    saving_timesheet: false,
    saving_timesheet_done: false,
    loading: false,
    nosaved: false
}

const timesheetReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_FOLLOWED_PROJECTS':
            return {
                ...state,
                followed_project_requested: true
            }

        case 'GET_FOLLOWED_PROJECTS_FULFILLED':
            return {
                ...state,
                followed_project_requested: true,
                followed_prj: action.payload,
            }

        case 'GET_TIMEESHET_MONTH':
            return {
                ...state,
                timesheet_act : {},
                timesheet_act_requested: true,
                request_again_timesheet: false,
                saving_timesheet: true,
                loading: true,
            }

        case 'GET_TIMEESHET_MONTH_PENDING':
            return {
                ...state,
                timesheet_act : {},
                timesheet_act_requested: true,
                request_again_timesheet: false,
                saving_timesheet: true,
                loading: true,
            }

        case 'GET_TIMEESHET_MONTH_FULFILLED':

            // metto in timesheet_indraft quelli che sono già in draft cosi in caso di submitting li aggiorno in SUBMITTED

            var temp_payload = action.payload
            var month_closed = temp_payload.mese_chiuso

            delete temp_payload.mese_chiuso

            var act_timesheet_indraft = []

            var disable_delete = {}

            Object.entries(temp_payload).map((day, k1) => {
                Object.entries(day[1].tasks).map((task, k2) => {
                    task[1].values.map((giorno, k3) => {
                        if(giorno.timesheet_status == "DRAFT"){
                            console.log(giorno)
                            act_timesheet_indraft.push(giorno)
                        }
                        if(giorno.timesheet_status == "SUBMITTED" || giorno.timesheet_status == "VALIDATED" || giorno.timesheet_status == "CLOSED"){
                            disable_delete[task[0]] = true
                        }
                    })
                })
            })

            console.log(disable_delete)

            return {
                ...state,
                disable_delete: disable_delete,
                saving_timesheet: false,
                saving_timesheet_done: true,
                request_again_timesheet: false,
                timesheet_act_requested: true,
                month_closed: month_closed,
                timesheet_act: temp_payload,
                timesheet_indraft: act_timesheet_indraft,
                nosaved: false,
                loading: false,
            }

        case 'ADD_TIMESHEET_LINE':
            let new_state_AL = {...state}

            let new_line_obj = {}
            new_line_obj[action.payload.project_id[0]] = {
                project_name: action.payload.project_id[1].project_name,
                tasks: {}
            }
            new_line_obj[action.payload.project_id[0]].tasks[action.payload.task_id.id] = {
                task_name: action.payload.task_id.name,
                values: []
            }

            let merged = mergeAdvanced(new_state_AL.timesheet_act, new_line_obj)

            new_state_AL.timesheet_act = merged

            return {...new_state_AL}

        case 'ADD_ONE_MONTH':
            let operation_now_add = state.operation_on_date + 1
            return {
                ...state,
                timesheet_act:{},
                request_again_timesheet: true,
                operation_on_date: operation_now_add,
                selected_timesheet_month: moment().add(operation_now_add, 'months').format('MM'),
                selected_timesheet_year: moment().add(operation_now_add, 'months').format('YYYY'),
                nosaved: true,
                selected_timesheet_date: function(){ return this.selected_timesheet_year + '-' + this.selected_timesheet_month},
                followed_project_requested: false,
                followed_prj: {},
                high_light_day: 0,
                timesheet_act_requested: false,
                timesheet_to_update: [],
                timesheet_to_insert: [],
                timesheet_indraft: [],
                month_closed: false,
                saving_timesheet: false,
                saving_timesheet_done: false,
                loading: false,
            }

        case 'SUB_ONE_MONTH':
            let operation_now_sub = state.operation_on_date - 1
            return {
                ...state,
                timesheet_act:{},
                request_again_timesheet: true,
                operation_on_date: operation_now_sub,
                selected_timesheet_month: moment().add(operation_now_sub, 'months').format('MM'),
                selected_timesheet_year: moment().add(operation_now_sub, 'months').format('YYYY'),
                nosaved: true,
                selected_timesheet_date: function(){ return this.selected_timesheet_year + '-' + this.selected_timesheet_month},
                followed_project_requested: false,
                followed_prj: {},
                high_light_day: 0,
                timesheet_act_requested: false,
                timesheet_to_update: [],
                timesheet_to_insert: [],
                timesheet_indraft: [],
                month_closed: false,
                saving_timesheet: false,
                saving_timesheet_done: false,
                loading: false,
            }
        
        case 'SAVE_TIMESHEET':
            return {
                ...state,
                saving_timesheet: true,
                saving_timesheet_done: false,
                timesheet_to_update: [],
                timesheet_to_insert: [],
                timesheet_indraft: [],
                month_closed: false,
            }

        case 'SET_BLANK_TIMESHEET':
            return {
                ...state,
                //operation_on_date: 0,
                //selected_timesheet_date: function(){ return this.selected_timesheet_year + '-' + this.selected_timesheet_month},
                followed_project_requested: false,
                followed_prj: {},
                high_light_day: 0,
                timesheet_act_requested: false,
                timesheet_act: {},
                timesheet_to_update: [],
                timesheet_to_insert: [],
                timesheet_indraft: [],
                request_again_timesheet: true,
                saving_timesheet: false,
                saving_timesheet_done: false,
                loading: true,
                nosaved: true
            }
        case 'DELETE_VIRT_TIMESHEET':
            var t_state = {...state}
            //console.log(tstate.timesheet_act)
            var t_timesheet_act = t_state.timesheet_act
            if(Object.keys(t_timesheet_act[action.payload.project].tasks).length == 1){
                delete t_timesheet_act[action.payload.project]
            } else {
                delete t_timesheet_act[action.payload.project].tasks[action.payload.task]
            }

            return {...state, timesheet_act: t_timesheet_act}

        case 'CHANGE_HL_DAY':
            return {
                ...state,
                high_light_day: action.payload                
            }

        case 'SAVE_TIMESHEET_FULFILLED':
            return {
                ...state,
                saving_timesheet: false,
                saving_timesheet_done: true,
                request_again_timesheet: true,
                timesheet_to_update: [],
                timesheet_to_insert: [],
                timesheet_indraft: [],
                month_closed: false,
            }

        case 'CHANGE_SPECIFIC_TIMESHEET':
            let new_state_C = state
            let obj_specific_task = {}

            new_state_C.timesheet_act[action.payload.project_id[0]].tasks[action.payload.task_id[0]].values.map((day,index) => {
                if (day.date == action.payload.day ){       
                    new_state_C.timesheet_act[action.payload.project_id[0]].tasks[action.payload.task_id[0]].values[index].unit_amount = action.payload.unit_amount
                    obj_specific_task = new_state_C.timesheet_act[action.payload.project_id[0]].tasks[action.payload.task_id[0]].values[index]
                }
            })

            // controllo se devo aggiornare uno dei record da inserire altrimenti lo metto in quelli da update che venivano da Odoo
            let finded_insert = false
            new_state_C.timesheet_to_insert.map((value, index) => {
                if (value.date == action.payload.day && value.project_id[0] == action.payload.project_id[0] && value.task_id[0] == action.payload.task_id[0]){
                    // da aggiornare
                    finded_insert = true
                    new_state_C.timesheet_to_insert[index].unit_amount = action.payload.unit_amount
                }
            })
            
            if (finded_insert == false && obj_specific_task.id > 0){
                let finded_update = false
                let updateOBJ = {
                    id: obj_specific_task.id,
                    display_name: 'Timesheet Adjustment',
                    name: 'Timesheet Adjustment',
                    date: action.payload.day,
                    unit_amount: action.payload.unit_amount,
                    company_id: obj_specific_task.company_id,
                    currency_id: obj_specific_task.currency_id,
                    employee_id: obj_specific_task.employee_id,
                    is_timesheet: true,
                    timesheet_invoice_type: 'billable_time',
                    task_id: [ action.payload.task_id[0], action.payload.task_id[1].task_name ],
                    project_id: [ action.payload.project_id[0], action.payload.project_id[1].project_name ]
                }
                new_state_C.timesheet_to_update.map((value, index) => {
                    if (value.date == action.payload.day && value.project_id[0] == action.payload.project_id[0] && value.task_id[0] == action.payload.task_id[0]){
                        finded_update = true
                        new_state_C.timesheet_to_update[index].unit_amount = action.payload.unit_amount
                    }
                })
                if(finded_update == false){
                    new_state_C.timesheet_to_update.push(updateOBJ)
                }
            }
            return {
                ...new_state_C
            }

        case 'ADD_SPECIFIC_TIMESHEET':
            let new_state_A = state

            let insertOBJ = {
                display_name: 'Timesheet Adjustment',
                name: 'Timesheet Adjustment',
                date: action.payload.day,
                unit_amount: action.payload.unit_amount,
                company_id: action.payload.user.userOdoo.company_id,
                currency_id: action.payload.user.userOdoo.currency_id,
                employee_id: action.payload.user.userOdoo.self,
                is_timesheet: true,
                timesheet_invoice_type: 'billable_time',
                task_id: [ action.payload.task_id[0], action.payload.task_id[1].task_name ],
                project_id: [ action.payload.project_id[0], action.payload.project_id[1].project_name ]
            }

            new_state_A.timesheet_act[action.payload.project_id[0]].tasks[action.payload.task_id[0]].values.push({...insertOBJ})
            new_state_A.timesheet_to_insert.push({...insertOBJ})

            return {
                ...new_state_A
                }
        default: 
            return state
    }
}

export default timesheetReducer