var JsonRpcWs = require('json-rpc-ws/browser');

var request = require('request-promise');

var encrypter = require('object-encrypter');
var engine = encrypter('A63IHD7DR7EW82JS89838U4JJ43IS99288HD7HD7DR7EW82JS8983DR7EW82JS89834U4JJ329893HD7HD7DR7EW82JS8983DR7EW82JS8983843UJ2NJSAIDSSHD7DR7EW82JS8983HD7DR7EW82JS898383993934I2JN22929JHD7DR7EW82JHD7DR7EW82JS8983S8983SJDSAISA9IJ', {
    ttl: true
});

const decrypt = (hex) => {
    let hex_dec = engine.decrypt(hex)
    delete hex_dec.expires
    return hex_dec
}

const encrypt = (obj) => {
    let hex_crypt = engine.encrypt(obj, 4000)
    return hex_crypt
}

//const serverprod = "http://localhost:2222"
const serverprod = "https://timesheet.lobra.com"
const odoo_api = {

    getuser_by_email: function (email) {
        console.log(email)
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/empinfo',
                qs: {
                    email: email
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    getfollowed_projects: function (employee_id) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/followprj',
                qs: {
                    empid: employee_id
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    gettimesheet_month: function (employee_id, year, month) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/monthts',
                qs: {
                    empid: employee_id,
                    year: year,
                    month: month
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    getall_external_emp: function () {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/allexternals',
                qs: {},
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    updatetimesheet_month: function (id, values) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'PUT',
                uri:  serverprod + '/odoo/timesheet',
                body: {
                    id: id,
                    values: values
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    inserttimesheet_month: function (values) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/timesheet',
                body: {
                    values: values
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    deletetimesheet_month: function (id) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'DELETE',
                uri:  serverprod + '/odoo/timesheet',
                body: {
                    id: id
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

}

export default odoo_api