import React from 'react';
import  {IconButton, Button, ButtonGroup, Fab, TextField}  from '@material-ui/core';
import { Image, Grid, Message, GridColumn, Header, Icon, Input, Table, Divider, Segment} from 'semantic-ui-react'
import { Row, Col} from 'antd';
import MenuIcon from '@material-ui/icons/Menu';
import  { DropdownButton, Dropdown, ListGroup }  from 'react-bootstrap';
import Timesheet from "./timesheet_view.js"
import UserLoggedin from '../Componenti/userloggedin_comp'
import CompTimesheet from './timesheet_view'

// CLASSI COMPONENTI  

  
  class Mese_giorno extends React.Component{

    render() {

      return(
  <div>
    <br></br>
    <br></br>
    <Row>
        <Col flex="auto" align='center'>
            <CompTimesheet.MeseTimesheet></CompTimesheet.MeseTimesheet>
        </Col>
    </Row>
    <Row>
        <Col flex="4">
          <Row>
            <Col flex="50%" align='center'>
              <CompTimesheet.DropDownProjects></CompTimesheet.DropDownProjects>
            </Col>
          </Row>
        </Col>
        <Col flex="12" align='center'>
          <CompTimesheet.Tab_GiorniMeseTimesheet></CompTimesheet.Tab_GiorniMeseTimesheet>
        </Col>
        <Col flex="4" align='center'>
          <CompTimesheet.AutoSaveTimesheet></CompTimesheet.AutoSaveTimesheet>
        </Col>
    </Row>

  </div>
        
      )
    }
  }
  
  class Intestazione extends React.Component{
    render() {
      return (

      <Row>
        <Col flex="5%" align='center'>
          <CompTimesheet.MenuExtra></CompTimesheet.MenuExtra>
        </Col> 
        <Col flex="15%" align='center'>
          <Image id='Logo' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
        </Col>
        <Col flex="60%" align='center'>
          <Message id='Oggetto' color='red' size='big'>External Timesheet</Message>
        </Col>
        <Col flex="20%" align='center'>
          <UserLoggedin></UserLoggedin>
        </Col>
      </Row>
      
      )
    }
  }
  
 

  // FINE CLASSI COMPONENTI 

  export{Intestazione, Mese_giorno}