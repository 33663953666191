import odoo_api from '../../FunzioniBE/odoo_lobra_wsapi'
import promise from 'promise'

export const getFollowedProjects = employee_id => ({ 
    type: 'GET_FOLLOWED_PROJECTS',
    payload: odoo_api.getfollowed_projects(employee_id)
})

export const getTimesheetMonth = (employee_id, year, month) => ({ 
    type: 'GET_TIMEESHET_MONTH',
    payload: odoo_api.gettimesheet_month(employee_id, year, month)
})

export const changeSpecificTimesheet = (value, project_id, task_id, day) => ({
    type: 'CHANGE_SPECIFIC_TIMESHEET',
    payload: {
        unit_amount: value,
        project_id: project_id,
        task_id: task_id,
        day: day
    }
})

export const addSpecificTimesheet = (user, value, project_id, task_id, day) => ({
    type: 'ADD_SPECIFIC_TIMESHEET',
    payload: {
        unit_amount: value,
        project_id: project_id,
        task_id: task_id,
        day: day,
        user: user
    }
})

export const addTimesheetLine = (project_id, task_id) => ({
    type: 'ADD_TIMESHEET_LINE',
    payload: {
        project_id: project_id,
        task_id: task_id
    }
})

export const ChangeHL_Day = (day) => ({
    type: 'CHANGE_HL_DAY',
    payload: day
})

export const setTimesheetBlank = () => ({
    type: 'SET_BLANK_TIMESHEET'
})

export const deleteLineTimesshet = (delete_ids) => {

    const promisearray = []
    delete_ids.map((idx) => {
        promisearray.push(odoo_api.deletetimesheet_month(idx))
    })

    return {
        type: 'SAVE_TIMESHEET',
        payload: promise.all(promisearray)
    }

}

export const deleteVirtualTimesshet = (lineinfo) => {
    return {
        type: 'DELETE_VIRT_TIMESHEET',
        payload: lineinfo
    }
}

export const add_1_Month = () => {
    return {
        type: 'ADD_ONE_MONTH'
    }
}

export const sub_1_Month = () => {
    return {
        type: 'SUB_ONE_MONTH'
    }
}

export const saveTimesheet = (insert_timesheet, update_timesheet, draft_to_submit_timesheet, submitting) => {

    const promisearray = []

/*     const objinsert = {
        name: 'Timesheet Adjustment',
        date: '2020-02-01',
        unit_amount: 8,
        company_id: 1,
        currency_id: 1,
        task_id: 112,
        project_id: 70, 
        employee_id: 18,
        is_timesheet: true,
        timesheet_invoice_type: 'billable_time',
        display_name: 'Timesheet Adjustment',
    }    */

    if(submitting){
        draft_to_submit_timesheet.map((ts_update) => {
            let unit_amount_temp = parseFloat((ts_update.unit_amount + '').replace(',','.'))
            console.log(unit_amount_temp)
            if (unit_amount_temp > 0){
                promisearray.push(odoo_api.updatetimesheet_month(ts_update.id, {
                    timesheet_status: 'SUBMITTED'          
                }))
            } else {
                promisearray.push(odoo_api.deletetimesheet_month(ts_update.id))
            }      
        })
    }

    insert_timesheet.map((ts_insert) => {

        var timesheet_status = 'DRAFT'
        if (submitting){
            timesheet_status = 'SUBMITTED'
        }

        const objinsert = {
            name: 'Timesheet Added from EXT_APP',
            date: ts_insert.date,
            unit_amount: parseFloat((ts_insert.unit_amount + '').replace(',','.')),
            company_id: parseInt(ts_insert.company_id[0]),
            currency_id: parseInt(ts_insert.currency_id[0]),
            task_id: parseInt(ts_insert.task_id[0]),
            project_id: parseInt(ts_insert.project_id[0]),
            employee_id: parseInt(ts_insert.employee_id[0]),
            timesheet_status: timesheet_status,
            is_timesheet: true,
            timesheet_invoice_type: 'billable_time',
            display_name: 'Timesheet Added from EXT_APP',
        }
            promisearray.push(odoo_api.inserttimesheet_month(objinsert))       
    })

    update_timesheet.map((ts_update) => {
        console.log(update_timesheet)
        let unit_amount_temp = parseFloat((ts_update.unit_amount + '').replace(',','.'))
        console.log(unit_amount_temp)
        var timesheet_status = 'DRAFT'
        if (submitting){
            timesheet_status = 'SUBMITTED'
        }
        if (unit_amount_temp > 0){
            promisearray.push(odoo_api.updatetimesheet_month(ts_update.id, {
                unit_amount: unit_amount_temp,
                timesheet_status: timesheet_status          
            }))
        } else {
            promisearray.push(odoo_api.deletetimesheet_month(ts_update.id))
        }      
    })

    return ({
        type: 'SAVE_TIMESHEET',
        payload: promise.all(promisearray)
    })
    
}



