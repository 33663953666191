import React from 'react'
import { Header, Icon, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Office365Login from './office365login'
import Cookies from 'universal-cookie';
import { Row, Col} from 'antd';


const UserLoggedin = ({ user, getFollowedProjects }) => {

    
    function Logout(){   
        window.location.href="https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=https://timesheet.lobra.com"
    }

    if (user.user365.isLogged365) {
        if (user.userOdoo.error){
            return (
            <Header id='omino' as='h3'>           
                <Header.Content>{user.userOdoo.error_msg}</Header.Content>        
            </Header>
            )
        } else {
            if (user.userOdoo.isLoggedOdoo) {
                return (
                    <div id="login_box">
                         <Row>
                            <Col flex="auto" align='right'>
                                <Icon id="login_icon" name='user' size='tiny' color='red' />
                            </Col>
                            <Col flex="auto" align='center'>
                                <h4>{user.userOdoo.self[1]}</h4>
                            </Col>
                            <Col flex="auto" align='left'>
                                <Icon id="login_icon" tooltip={"Logout"} className='logout' name='log out' onClick={Logout}/>    
                            </Col>
                        </Row>
                    </div>
                )
            } else {
                return(
                    <div></div>
                )
            }     
        }
         
    } else {
        return (
            <Header id='omino' as='h3'>
                <Office365Login></Office365Login>
            </Header>
        )
    }



}


const mapStateToProps = state => ({
    user: state.user.info
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps )(UserLoggedin)