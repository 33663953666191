const INITIAL_STATE = {
    info: {
        user365: {
            isLogged365: false,
            error: false,
            name: null
        },
        userOdoo: {
            isLoggedOdoo: false,
            error: false,
            error_msg: null,  
            uid: null,
            self: null,
            company_id: null,
            currency_id: null
        },
        all_emp_users: [],
        force_reload: false
    },
    
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_EXT_EMPLOYEE_FULFILLED':
            var users = [{work_email:state.info.userOdoo.email,resource_id:state.info.userOdoo.self},...action.payload]
            console.log(users)
            var temp_state = {
                ...state,
                info: {
                    ...state.info,
                    all_emp_users: users
                } 
            }
            return temp_state
        case 'OFFICE_365_LOGIN':
            return {
                ...state,
                info: {
                    ...state.info,
                    user365: action.payload
                }
            }
        case 'GET_ODOO_USER_BYEMAIL_FULFILLED':

            return {
                ...state,
                info: {
                    ...state.info,
                    userOdoo: {
                        email: action.payload.email,
                        isLoggedOdoo: true,
                        error: action.payload.error,
                        error_msg: action.payload.error_msg,
                        uid: action.payload.id,
                        self: action.payload.self,
                        company_id: action.payload.company_id,
                        currency_id: action.payload.currency_id
                    },
                    force_reload: false
                }
            }

        case 'GET_ODOO_USER_BYEMAIL_FORCED_FULFILLED':
            return {
                   ...state,
                   info: {
                       ...state.info,
                       userOdoo: {
                           email: action.payload.email,
                           isLoggedOdoo: true,
                           error: action.payload.error,
                           error_msg: action.payload.error_msg,
                           uid: action.payload.id,
                           self: action.payload.self,
                           company_id: action.payload.company_id,
                           currency_id: action.payload.currency_id
                       },
                       force_reload: true
                   }
               }
        case 'SET_FORCE_RELOAD_FALSE':
            return {
                ...state,
                info: {
                    ...state.info,
                    force_reload: false
                }
            }

        case 'SET_FORCE_RELOAD_TRUE':
            return {
                ...state,
                info: {
                    ...state.info,
                    force_reload: true
                }
            }

        default: 
            return state
    }
}

export default userReducer