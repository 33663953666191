import React from 'react';
import './App.css';
import './font_odoo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid, Divider } from 'semantic-ui-react'
import {Intestazione, Mese_giorno} from './Componenti/componenti.js'
import CompTimesheet from './Componenti/timesheet_view'

function App() {
  return (
    <div className="App">

      <Intestazione></Intestazione>
      <Mese_giorno></Mese_giorno>
      <CompTimesheet.GenerateAllTimesheet_Lines></CompTimesheet.GenerateAllTimesheet_Lines>
      <CompTimesheet.TimeSheet_Total_Hour></CompTimesheet.TimeSheet_Total_Hour>
      <CompTimesheet.ActionSubmit></CompTimesheet.ActionSubmit>
      <CompTimesheet.AutoGetInfoFunction></CompTimesheet.AutoGetInfoFunction>

    </div>
  );
}

export default App;
