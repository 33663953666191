import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import { connect } from "react-redux"
import { setCurrentUser, getOdooUserInfo} from "../Redux/User/user-action"



const Office365Login = ({ setCurrentUser, getOdooUserInfo }) => {
    
    function LoginHandler(err, data){
        const login365 = {
                isLogged365: true,
                error: false,
                name: data.authResponseWithAccessToken.account.name        
        }
        
        setCurrentUser(login365)
        getOdooUserInfo(data.authResponseWithAccessToken.account.userName)
    }

    return (
    <div>
        <MicrosoftLogin clientId={"c63ebed7-0b83-44b8-a3d2-4a85c7d0ea46"} authCallback={LoginHandler} />
    </div>
    )
}


    
const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    getOdooUserInfo: email => dispatch(getOdooUserInfo(email)),
})

export default connect(null, mapDispatchToProps)(Office365Login)