import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Grid, Input } from 'semantic-ui-react'
import { setForcePersonationFalse, setForcePersonationTrue, getOdooUserInfo_forced, getAllExternalEmp, getOdooUserInfo } from "../Redux/User/user-action"
import { deleteVirtualTimesshet, ChangeHL_Day,sub_1_Month, add_1_Month, deleteLineTimesshet, setTimesheetBlank, getFollowedProjects, getTimesheetMonth , changeSpecificTimesheet, addSpecificTimesheet, addTimesheetLine, saveTimesheet} from "../Redux/User/timesheet-action"
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Drawer, Select, InputNumber ,Divider, Typography, Row, Col, Button, Popconfirm, message, Dropdown, Menu} from 'antd';
import '../Redux/antd.css'
import {ReloadOutlined ,DownOutlined, UserOutlined, DeleteTwoTone, LeftOutlined, RightOutlined, SyncOutlined} from '@ant-design/icons'

import moment from 'moment'

const { Title , Text, Link } = Typography;
const { Option } = Select


// FUNZIONI UTILITY
function getAllDatesOfMonth(date) {
    const mDate = moment(date, "YYYY-MM");
    const daysCount = mDate.daysInMonth();
    return Array(daysCount).fill(null).map((v,index)=>{
        const addDays = index == 0 ? 0 : 1;
        return mDate.add(addDays, 'days').format('YYYY-MM-DD');
    });
}

/* ---------------------------- */
/*  CONNECTED AUTOGET FUNCTION  */
/* ---------------------------- */

const AutoGetInfoFunction = ({user, timesheet, getFollowedProjects, getTimesheetMonth, setTimesheetBlank, setForcePersonationFalse, ChangeHL_Day}) => {
    if (user.userOdoo.isLoggedOdoo && timesheet.timesheet_act_requested == false) {
        setTimesheetBlank()
        getFollowedProjects(user.userOdoo.uid)
        getTimesheetMonth(user.userOdoo.self,timesheet.selected_timesheet_year, timesheet.selected_timesheet_month)
    }
    if (user.userOdoo.isLoggedOdoo && timesheet.request_again_timesheet == true ) {
        setTimesheetBlank()
        getFollowedProjects(user.userOdoo.uid)
        getTimesheetMonth(user.userOdoo.self,timesheet.selected_timesheet_year, timesheet.selected_timesheet_month)
        if (timesheet.nosaved == false){
            message.success('Operation Completed Successfully!');
        }
    }

    if (user.force_reload == true){
        setTimesheetBlank()
        getFollowedProjects(user.userOdoo.uid)
        getTimesheetMonth(user.userOdoo.self,timesheet.selected_timesheet_year, timesheet.selected_timesheet_month)
        setForcePersonationFalse()
    }

    if (user.userOdoo.isLoggedOdoo && timesheet.followed_project_requested == false) {
        getFollowedProjects(user.userOdoo.uid)
    }

    if (timesheet.loading == true){
        return(
            <center>
                <div id="loading_box">
                    <SyncOutlined id="caricamento_icona" style={{ fontSize: 48, color: "red" }} spin />         
                </div>
            </center>
        )
    }

    return <div></div>
}

// timer

const AutoSaveTimesheet = ({user, timesheet, saveTimesheet, setForcePersonationTrue}) => {

    const onClickRefresh = () => {
        setForcePersonationTrue()
    }

    const onClickSave = () => {
        saveTimesheet(timesheet.timesheet_to_insert, timesheet.timesheet_to_update, [], false)
    }

    const OnClickSubmit = () => {
        saveTimesheet(timesheet.timesheet_to_insert, timesheet.timesheet_to_update, timesheet.timesheet_indraft, true)
    }

    if (user.userOdoo.isLoggedOdoo == false){
        return <div></div>
    }

    if (timesheet.saving_timesheet == true) {
        return <button class="ui loading primary button">Loading</button>
    } else {
        if (timesheet.saving_timesheet_done == true ){
            return( 
                <div id="div_save">
                <Popconfirm
                    title="All unsaved information will be lost, do you want to continue?"
                    onConfirm={onClickRefresh}
                    okText="Yes"
                    cancelText="No"
                >
                <Button type="primary" hidden={timesheet.month_closed ? true : false}><ReloadOutlined id="reload_icon"/></Button>
                </Popconfirm>
                {'         '}
                <Popconfirm
                    title="Are you sure you want to save this draft?"
                    onConfirm={onClickSave}
                    okText="Yes"
                    cancelText="No"
                >
                <Button type="primary" hidden={timesheet.month_closed ? true : false}>Save Draft</Button>
                </Popconfirm>
                {'         '}
                <Popconfirm
                    title="No changes can be made to the days that have been sent, Are you sure you want to submit this draft?"
                    onConfirm={OnClickSubmit}
                    okText="Yes"
                    cancelText="No"
                >
                <Button type="primary" hidden={timesheet.month_closed ? true : false}>Submit</Button>
                </Popconfirm>
                
           {/*  <button onClick={onClickSave} class="ui primary button">Save Timesheet</button> */}
            </div>
            );
        } else {
            // call GetTimesheet
            return <button class="ui loading primary button">Loading</button>
        }
    }
    
/*     return <Button onClick={onClickSave} id='bottone' variant="primary">Save Timesheet</Button>
 */}


/* --------------------------------------- */
/* --- DROPDOWN LIST FOLLOWED PROJECTS --- */
/* --------------------------------------- */

const DropDownProjects = ({ timesheet, addTimesheetLine}) => {

    const func_click_task = (e) => {
        addTimesheetLine(e.item.props.current_project, e.item.props.current_task)
    }

    if (timesheet.followed_project_requested) {
        return (
            <Dropdown overlay={
            <Menu>
                    {
                        Object.entries(timesheet.followed_prj).map((t, k) => {
                            return (
                                <Menu.SubMenu title={t[1].project_name}>             
                                            {
                                                t[1].values.map((value, index) => {
                                                    return (
                                                        <Menu.Item current_project={t} current_task={value} onClick={func_click_task} taskid={value.name}>{value.name}</Menu.Item>
                                                    )
                                                })
                                            }
                             </Menu.SubMenu>
                            )
                        })
                    }

            </Menu>}>
    <Button id="prj_menu" hidden={timesheet.month_closed ? true : false}>
       Add Project<DownOutlined />
      </Button>
  </Dropdown>

        )

    } else {
        return (
            <div></div>
        )
    }
}

/* --------------------------------------- */
/* ---  NOME MESE TIMESHEET   ------------ */
/* --------------------------------------- */

const MeseTimesheet = ({user, timesheet, sub_1_Month, add_1_Month, setTimesheetBlank, setForcePersonationTrue}) => {

    const AddMese = () => {
        add_1_Month()
    }

    const SubMese = () => {
        sub_1_Month()
    }

    if (user.userOdoo.isLoggedOdoo){
        const LabelTimesheetAttuale = moment(timesheet.selected_timesheet_date()).format('MMMM YYYY')
        return (

            <div>
                <Row>
                    <Col flex="41%">
                    </Col>
                    <Col flex="20px" align='center'>
                        <LeftOutlined onClick={SubMese} id='fr_sinistra'></LeftOutlined>
                    </Col>
                    <Col flex="auto" align='center'>
                        <h4 className="month_header">{'  ' + LabelTimesheetAttuale+ '  '}</h4>
                    </Col>
                    <Col flex="20px" align='center'>
                        <RightOutlined onClick={AddMese} id='fr_destra'></RightOutlined>    
                    </Col>
                    <Col flex="41%">
                    </Col>
                </Row>
                <br></br><br></br>
            </div>

        )
        
    } else {
        return (<div></div>)
    }
    
}

/* --------------------------------------- */
/* ---  ELENCO GIORNI MESE TIMESHEET   --- */
/* --------------------------------------- */

const Tab_GiorniMeseTimesheet = ({user, timesheet}) => {
    if (user.userOdoo.isLoggedOdoo){
        const DaysInMonth = getAllDatesOfMonth(timesheet.selected_timesheet_date())
        const WeekDayHoliday = ['Saturday','Sunday']

        const MappingGiorniSettimana = {
            Monday: 'Mon',
            Tuesday: 'Tue',
            Wednesday: 'Wed',
            Thursday: 'Thu',
            Friday: 'Fri',
            Saturday: 'Sat',
            Sunday: 'Sun'
        }

        return (
            <table className='bordo_esterno'>
                <thead>
                    <tr class='giorni_settimana'>
                    {
                        DaysInMonth.map((day) => {
                            return (
                               <th className=''>{MappingGiorniSettimana[moment(day, 'YYYY-MM-DD').format('dddd')]}</th>
                            )
                        })
                    }
                    </tr>
                    <tr>
                    {
                        DaysInMonth.map((day) => {
                            if (WeekDayHoliday.includes(moment(day, 'YYYY-MM-DD').format('dddd'))){
                                return (
                                    <th className={timesheet.high_light_day == moment(day, 'YYYY-MM-DD').format('DD') ? 'bordo festivi_giorni hl_day' : 'bordo festivi_giorni '}>{moment(day, 'YYYY-MM-DD').format('DD')}</th>
                                )
                            } else {
                                return(
                                    <th className={timesheet.high_light_day == moment(day, 'YYYY-MM-DD').format('DD') ? 'bordo hl_day' : 'bordo '}>{moment(day, 'YYYY-MM-DD').format('DD')}</th>
                                )
                            }
                        })   
                    }
                    </tr>
                </thead>
            </table>
        )
    } else {
        return (<div></div>)
    }

}

const GenerateAllTimesheet_Lines = ({user, timesheet, deleteLineTimesshet, changeSpecificTimesheet, addSpecificTimesheet, ChangeHL_Day, deleteVirtualTimesshet}) => {
    return (
        Object.entries(timesheet.timesheet_act).map((project, k1) => {
            return (
                Object.entries(project[1].tasks).map((task, k2) => {
                    var sameProject = k2 == 0 ? false : true
                    return (
                        <TimeSheet_Line user={user} project={project} task={task} timesheet={timesheet} deleteLineTimesshet={deleteLineTimesshet} changeSpecificTimesheet={changeSpecificTimesheet} ChangeHL_Day={ChangeHL_Day} addSpecificTimesheet={addSpecificTimesheet} sameProject={sameProject} deleteVirtualTimesshet={deleteVirtualTimesshet} />
                    )              
                }) 
            )
        })
    )
}

class TimeSheet_Line extends React.Component{

    state = {
        delete_disable: false
    }

    render(){

    var user = this.props.user
    var timesheet = this.props.timesheet
    var project = this.props.project
    var task = this.props.task

    var sameProject = this.props.sameProject

    var deleteLineTimesshet = this.props.deleteLineTimesshet
    var changeSpecificTimesheet = this.props.changeSpecificTimesheet
    var addSpecificTimesheet = this.props.addSpecificTimesheet
    var ChangeHL_Day = this.props.ChangeHL_Day
    var deleteVirtualTimesshet = this.props.deleteVirtualTimesshet

    const DaysInMonth = getAllDatesOfMonth(timesheet.selected_timesheet_date())
    const WeekDayHoliday = ['Saturday','Sunday']

    /*const RemoveDeleteLine = () => {
        DaysInMonth.map((day) => {
            var day = task[1].values.find(info => info.date == day)
            if(day !== undefined){
                if(day.timesheet_status == "SUBMITTED" || day.timesheet_status == "VALIDATED" || day.timesheet_status == "CLOSED"){
                        this.state.delete_disable = true
                }
            }
        })
    }*/

    const Get_CellStatus = (timesheet_status, holyday) => {

            // Check timesheet_status

            // DRAFT
            // SUBMITTED
            // VALIDATED
            // REFUSED
            // CLOSED

        switch(holyday) {
            case true:
              // SI

              switch(timesheet_status) {
                case 'DRAFT':
                  // DRAFT FESTIVO
                  return {
                    disabled: false,
                    classname: 'draft_festivo',
                }

                case 'SUBMITTED':
                  // SUBMITTED FESTIVO
                  return {
                    disabled: true,
                    classname: 'submitted_festivo',
                }

                case 'VALIDATED':
                  // VALIDATED FESTIVO
                  return {
                    disabled: true,
                    classname: 'validated_festivo',
                }

                case 'CLOSED':
                  // CLOSED FESTIVO
                  return {
                    disabled: true,
                    classname: 'closed_festivo',
                }

                case 'REFUSED':
                  // REFUSED FESTIVO
                  return {
                    disabled: false,
                    classname: 'refused_festivo',
                }

                default:
                    return {
                        disabled: false,
                        classname: 'festivo_ore',
                    } 

              } 

            case false:
              // NO

              switch(timesheet_status) {

                case 'DRAFT':
                  // DRAFT NON FESTIVO
                  return {
                    disabled: false,
                    classname: 'draft_no_festivo',
                }

                case 'SUBMITTED':
                    
                  // SUBMITTED NON FESTIVO
                  return {
                    disabled: true,
                    classname: 'submitted_no_festivo',
                }

                case 'VALIDATED':
                  // VALIDATED NON FESTIVO
                  return {
                    disabled: true,
                    classname: 'validated_no_festivo',
                }

                case 'CLOSED':
                  // CLOSED NON FESTIVO
                  return {
                    disabled: true,
                    classname: 'closed_no_festivo',
                    }

                case 'REFUSED':
                  // REFUSED NON FESTIVO
                  return {
                    disabled: false,
                    classname: 'refused_no_festivo',
                  }

                default:
                    return {
                        disabled: false,
                        classname: '',
                    } 
              } 
        } 
    }

    const HoursInputChange = (c_value,c_project,c_task,c_day) => {
        changeSpecificTimesheet(c_value,c_project,c_task,c_day)
    }

    const HoursInputAdded = (c_value,c_project,c_task,c_day) => {
        addSpecificTimesheet(user, c_value,c_project,c_task,c_day)
    }

    var delete_line_info = {project: project[0], task: task[0]}
    var delete_ids = []

    const DeleteHours = () => {
        if(delete_ids.filter((value) => {return value != undefined}).length == 0){
            deleteVirtualTimesshet(delete_line_info)
        } else {
            deleteLineTimesshet(delete_ids)
        }
    }

    var total_hours_line = 0

    return (
    <div>
        {
            sameProject == true ? <div></div> : <Divider>{project[1].project_name}</Divider>
        }
        <Row>
            <Col flex="3.5" align="right">
                <div class='container_prj'>         
                    <h6 style={{ marginTop: '6px' }} className="h6_s">{task[1].task_name}</h6>
                </div>
            </Col>
            <Col flex="0.5" align="middle">
            {
                        timesheet.disable_delete[task[0]] ? <div key={project[1].project_name + "_" + task[1].task_name}></div> : (
                            <Popconfirm 
                                title="Are you sure you want to delete this line of Timesheet?"
                                onConfirm={DeleteHours}
                                okText="Yes"
                                cancelText="No"
                            >
                            <DeleteTwoTone />
                                
                            </Popconfirm>
                        )
            }                     
            </Col>

            <Col flex="12" align="middle">
            <table className='bordo_esterno_ora'>   
                        <tbody>
                            <tr>
                            {
                            DaysInMonth.map((day, index) => {

                                let found_info_day_timesheet = {}
                                let CurrentHoursFunction = HoursInputAdded

                                if (task[1].values.length == 0){
                                    const fake0 = ''
                                    return (
                                        <td id={"progetto"} className='bordo_ora new_added_ore'><InputNumber size="small" min={0} max={15} bordered={false} decimalSeparator={"."} value={fake0} disabled={timesheet.month_closed ? true : found_info_day_timesheet.validated} onFocus={() => {ChangeHL_Day(moment(day).format("DD"))}} onChange={(value) => {CurrentHoursFunction(value, project, task, day)}} /></td>
                                    )
                                } else {
                                    task[1].values.map((info) => {  
                                        if (info.date == day ){
                                            found_info_day_timesheet = info
                                            delete_ids.push(found_info_day_timesheet.id)
                                            CurrentHoursFunction = HoursInputChange
                                        }
                                    }) 
                                }
                                
                                if (WeekDayHoliday.includes(moment(day, 'YYYY-MM-DD').format('dddd'))){
                                    if (found_info_day_timesheet.unit_amount == undefined) {
                                        
                                        return (
                                            <td id={"progetto"} className='bordo_ora festivi_ore'><InputNumber size="small" min={0} max={15} bordered={false} decimalSeparator={"."} disabled={timesheet.month_closed ? true : false} onFocus={() => {ChangeHL_Day(moment(day).format("DD"))}} onChange={(value) => {CurrentHoursFunction(value, project, task, day)}} /></td>
                                            )
                                    } else {
                                        let hrn = 0
                                        if (found_info_day_timesheet.unit_amount == '' ){
                                            hrn = 0
                                        } else {
                                            hrn = found_info_day_timesheet.unit_amount
                                        }
                                        total_hours_line = parseFloat((total_hours_line + '').replace(",",'.').replace('',0)) +  parseFloat((hrn + '').replace(",",'.').replace('', 0))

                                        var act_check_status = Get_CellStatus(found_info_day_timesheet.timesheet_status, true)

                                        return (
                                            <td id={"progetto"} className={'bordo_ora ' + act_check_status.classname}><InputNumber size="small" min={0} max={15} bordered={false} decimalSeparator={"."} disabled={timesheet.month_closed ? true : act_check_status.disabled} value={found_info_day_timesheet.unit_amount} onFocus={() => {ChangeHL_Day(moment(day).format("DD"))}} onChange={(value) => {CurrentHoursFunction(value, project, task, day)}} /></td>

                                        )
                                    }
                                } else {
                                    if (found_info_day_timesheet.unit_amount == undefined) {
                                        return (
                                            <td id={"progetto"} className={'bordo_ora'}><InputNumber size="small" min={0} max={15} bordered={false} decimalSeparator={"."} disabled={timesheet.month_closed ? true : false} value={found_info_day_timesheet.unit_amount} onFocus={() => {ChangeHL_Day(moment(day).format("DD"))}} onChange={(value) => {CurrentHoursFunction(value, project, task, day)}} /></td>

                                        )
                                    } else {
                                        let hrn = 0
                                        if (found_info_day_timesheet.unit_amount == '' ){
                                            hrn = 0
                                        } else {
                                            hrn = found_info_day_timesheet.unit_amount
                                        }
                                        total_hours_line = parseFloat((total_hours_line + '').replace(",",'.').replace('',0)) +  parseFloat((hrn + '').replace(",",'.').replace('',0))

                                        var act_check_status = Get_CellStatus(found_info_day_timesheet.timesheet_status, false)



                                        return (
                                            <td id={"progetto"} className={'bordo_ora ' + act_check_status.classname}><InputNumber size="small" min={0} max={15} bordered={false} decimalSeparator={"."} disabled={timesheet.month_closed ? true : act_check_status.disabled} value={found_info_day_timesheet.unit_amount} onFocus={() => {ChangeHL_Day(moment(day).format("DD"))}} onChange={(value) => {CurrentHoursFunction(value, project, task, day)}} /></td>
                                        )
                                    }
                                }
                                
                            })
                             }
                              
                            </tr>
                        </tbody>
                </table>
                
            </Col>
            <Col flex="0.5" align="left"> 
                <table className='bordo_esterno_ora'>
                    <tbody>
                        <tr>
                            <td className='bordo_ora_tot'><center>{total_hours_line}</center></td>
                        </tr>
                    </tbody>
                </table>
            </Col>
            <Col flex="3.5"> 
            </Col>
        </Row>
      
    </div>
    )
    }
}

const TimeSheet_Total_Hour = ({user, timesheet}) => {
    
Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

    if (user.userOdoo.isLoggedOdoo && Object.size(timesheet.timesheet_act) > 0){

    let total_incrocio = 0

    const DaysInMonth = getAllDatesOfMonth(timesheet.selected_timesheet_date())
    const WeekDayHoliday = ['Saturday','Sunday']

    return (
    <div>
        
        <Divider />

        <Row>
            <Col flex="3.8" align="right"><h4 id='nome_progetto'>{"Total Hours: "}</h4></Col>
            <Col flex="0.2"></Col>
            <Col flex="12" align="middle">
            <table className='bordo_esterno_ora'>
                    <tbody>
                        <tr>
                        {
                        DaysInMonth.map((day) => {

                            let count_day_hours = 0

                            Object.entries(timesheet.timesheet_act).map((project) => {
                                Object.entries(project[1].tasks).map((task) => {
                                    Object.entries(task[1].values).map((giorno) => {
                                        if (giorno[1].date == day){
                                            count_day_hours = (count_day_hours +'').replace(",",'.').replace('',0); 
                                            let count_act_hours = (giorno[1].unit_amount + '').replace(",",'.').replace('',0);
                                            let count_dh = parseFloat(count_day_hours);
                                            let count_acth = parseFloat(count_act_hours)
                                            count_day_hours = count_dh + count_acth   
                                        }
                                    })
                                })    
                            })
                              if (WeekDayHoliday.includes(moment(day, 'YYYY-MM-DD').format('dddd'))){
                                total_incrocio = total_incrocio + count_day_hours
                                return (
                                    <td id={"progetto"} className='bordo_ora_tot_s festivi_blu'><Input fluid transparent disabled value={count_day_hours}/></td>
                                )
                            } else {
                                total_incrocio = total_incrocio + count_day_hours
                                return (
                                    <td id={"progetto"} className='bordo_ora_tot_s'><Input fluid transparent disabled value={count_day_hours}/></td>                                        )
                                }
                            })
                        }       
                        </tr>
                    </tbody>
                </table>
            </Col>
            <Col flex="0.5" align="left"> 
                <table className='bordo_esterno_ora'>
                    <tbody>
                        <tr>
                            <td className='bordo_ora_tot'><center>{total_incrocio}</center></td>                            
                        </tr>
                    </tbody>
                </table>      
            </Col>
            <Col flex="3.5" align="left">     
            </Col>
        </Row>

    </div>
    )

    } else {
        return(<div></div>)
    }

}

const MenuExtra = ({user, timesheet, getFollowedProjects, getAllExternalEmp, getOdooUserInfo_forced, getTimesheetMonth, setTimesheetBlank, setForcePersonationFalse}) => {
    
    const LoadEmpUsers = () => {
        getAllExternalEmp()
    }



    if (user.force_reload == true){
        setTimesheetBlank()
        getFollowedProjects(user.userOdoo.uid)
        getTimesheetMonth(user.userOdoo.self,timesheet.selected_timesheet_year, timesheet.selected_timesheet_month)
        setForcePersonationFalse()
    }

    

    if (user.userOdoo.isLoggedOdoo){
        if (user.user365.name == 'Leonardo Cittadino' || user.user365.name == 'Antonio Napolitano' || user.user365 =='Elena Rostirolla' || user.user365 =='Francesca Cataldi' || user.user365 == 'Silvia Salvian' || user.user365 == 'Pamela Suriano'){
            if(user.all_emp_users.length == 0){
                LoadEmpUsers()
            }
            /*return (
                    
                <div className="impers_dropdown">
                    <Dropdown.Button overlay={
                        <Menu onClick={() => {}}>
                            {
                                user.all_emp_users.map((userx, index) => {
                                    return (
                                        <Menu.Item key={index} icon={<UserOutlined />} onClick={() => ForceLoadExtEmp(userx.work_email)}>{userx.resource_id[1]}</Menu.Item>
                                        
                                    )
                                })
                            }
                        </Menu>
                    } placement="bottomCenter" icon={<UserOutlined />}>
                    </Dropdown.Button>
                    
                </div>
                
            )*/

            return (
                <PersonationPanel user={user} timesheet={timesheet} getFollowedProjects={getFollowedProjects} getAllExternalEmp={getAllExternalEmp} getOdooUserInfo_forced={getOdooUserInfo_forced} getTimesheetMonth={getTimesheetMonth} setTimesheetBlank={setTimesheetBlank} setForcePersonationFalse={setForcePersonationFalse}/>       
            )
        } else {
            return <div></div>
        }
        
    } else {
        return <div></div>
    }
}

const ActionSubmit = ({user, timesheet}) => {

    if (user.userOdoo.isLoggedOdoo){
        return (
            
        <Grid>
            <Grid.Row>
                <Grid.Column width={4}>

                </Grid.Column>

                <Grid.Column width={9}>
         
                </Grid.Column>

                <Grid.Column width={2}>
                    {/* <br></br>
                    <button onClick={SubmitClick} class="ui primary button">Submit Timesheet</button> */}
                </Grid.Column>

            </Grid.Row>
        </Grid>
        )
    } else {
        return (<div></div>)
    }
}


class PersonationPanel extends React.Component {
    state = { visible: false, placement: 'left' };
  

    showDrawer = () => {
      this.setState({
        visible: true,
      });
    };
  
    onClose = () => {
      this.setState({
        visible: false,
      });
    };
  
    onChange = e => {
      console.log(e.target.value)
      this.setState({
        placement: e.target.value,
      });
    };


    render() {

      var ForceLoadExtEmp = (email_user) => {
            getOdooUserInfo_forced(email_user)
      }
      
 
      var getFollowedProjects = this.props.getFollowedProjects
      var getAllExternalEmp = this.props.getAllExternalEmp
      var getOdooUserInfo_forced = this.props.getOdooUserInfo_forced
      var getTimesheetMonth = this.props.getTimesheetMonth
      var setTimesheetBlank = this.props.setTimesheetBlank
      var setForcePersonationFalse= this.props.setForcePersonationFalse
      var user = this.props.user
      var timesheet = this.props.timesheet
      const { placement, visible } = this.state;
      return (
        <>
          <Button className="impers_dropdown" type="primary" shape="circle" icon={<UserOutlined />} onClick={this.showDrawer} />
          <Drawer
            title=""
            placement={"left"}
            closable={false}
            onClose={this.onClose}
            width={300}
            visible={visible}
            key={"left"}
          >
            {/* Scelta Employee Esterni */}
            <><center><Title level={4}>Ricerca Employee Esterni</Title></center></>
            <Select
                showSearch
                style={{ width: 250 }}
                onChange={(email) => {ForceLoadExtEmp(email)}}
                placeholder="Cerca..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
            >
                {
                    user.all_emp_users.map((userx, index) => {
                        return (
                            <Option key={index} value={userx.work_email}>{userx.resource_id[1]}</Option>             
                        )
                    })
                }
            </Select>

            {/* Scelta Employee Interni */}
            <><br></br><br></br><center><Title level={4}>Ricerca Employee Interni</Title></center></>
            <Select
                showSearch
                style={{ width: 250 }}
                onChange={(email) => {ForceLoadExtEmp(email)}}
                placeholder="Cerca..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
            >
                {
                    /*user.all_emp_users.map((userx, index) => {
                        return (
                            <Option key={index} value={userx.work_email}>{userx.resource_id[1]}</Option>             
                        )
                    })
                    */
                }
            </Select>
          </Drawer>
        </>
      );
    }
  }



/* --------------------------------------- */
/* ---  ELENCO GIORNI MESE TIMESHEET   --- */
/* --------------------------------------- */


const mapStateToProps = state => ({
    timesheet: state.timesheet,
    user: state.user.info
})

const mapDispatchToProps = dispatch => ({
    getOdooUserInfo: (email) => dispatch(getOdooUserInfo(email)),
    getAllExternalEmp: () => dispatch(getAllExternalEmp()),
    getFollowedProjects: employee_id => dispatch(getFollowedProjects(employee_id)),
    getTimesheetMonth: (employee_id, year, month) => dispatch(getTimesheetMonth(employee_id, year, month)),
    changeSpecificTimesheet: (value, project_id, task_id, day) => dispatch(changeSpecificTimesheet(value, project_id, task_id, day)),
    addSpecificTimesheet: (user, value, project_id, task_id, day) => dispatch(addSpecificTimesheet(user, value, project_id, task_id, day)),
    addTimesheetLine: (project_id, task_id) => dispatch(addTimesheetLine(project_id, task_id)),
    saveTimesheet: (insert_timesheet, update_timesheet, draft_to_submit_timesheet, submitting) => dispatch(saveTimesheet(insert_timesheet, update_timesheet, draft_to_submit_timesheet, submitting)),
    setForcePersonationFalse: () => dispatch(setForcePersonationFalse()),
    ChangeHL_Day: (day) => dispatch(ChangeHL_Day(day)),
    setForcePersonationTrue: () => dispatch(setForcePersonationTrue()),
    getOdooUserInfo_forced: (project_id, task_id) => dispatch(getOdooUserInfo_forced(project_id, task_id)),
    setTimesheetBlank: () => dispatch(setTimesheetBlank()),
    deleteLineTimesshet: (ids) => dispatch(deleteLineTimesshet(ids)),
    sub_1_Month: () => dispatch(sub_1_Month()),
    add_1_Month: () => dispatch(add_1_Month()),
    deleteVirtualTimesshet:(lineinfo) => dispatch(deleteVirtualTimesshet(lineinfo))
    //MeseTimesheet: connect(mapStateToProps,mapDispatchToProps)(comp_mese_timesheet),
})

export default {
    DropDownProjects: connect(mapStateToProps, mapDispatchToProps)(DropDownProjects),
    Tab_GiorniMeseTimesheet: connect(mapStateToProps, mapDispatchToProps)(Tab_GiorniMeseTimesheet),
    MeseTimesheet: connect(mapStateToProps, mapDispatchToProps)(MeseTimesheet),
    TimeSheet_Total_Hour: connect(mapStateToProps, mapDispatchToProps)(TimeSheet_Total_Hour),
    AutoGetInfoFunction: connect(mapStateToProps, mapDispatchToProps)(AutoGetInfoFunction),
    GenerateAllTimesheet_Lines: connect(mapStateToProps, mapDispatchToProps)(GenerateAllTimesheet_Lines),
    AutoSaveTimesheet: connect(mapStateToProps, mapDispatchToProps)(AutoSaveTimesheet),
    ActionSubmit: connect(mapStateToProps, mapDispatchToProps)(ActionSubmit),
    MenuExtra: connect(mapStateToProps, mapDispatchToProps)(MenuExtra),
}




